import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from 'Layout'
import SEO from 'SEO'
import ErrorPage from 'ErrorPage'

const ErrorPageTemplate = () => {
  const {
    frontmatter,
    html,
    fields,
    excerpt
  } = useStaticQuery(
    graphql`
      query {
        markdownRemark (
          fields: {
            slug: {
              regex: "/404/"
            }
          }
        ) {
          html
          excerpt
          fields {
            slug
          }
          frontmatter {
            backgroundImages {
              mobile
              desktop
            }
          }
        }
      }
    `
  ).markdownRemark

  return (
    <Layout hideFooter>
      <SEO
        title='404'
        description={excerpt}
        slug={fields.slug}
        image={frontmatter.backgroundImages.desktop}
      />
      <ErrorPage
        body={html}
        {...frontmatter}
      />
    </Layout>
  )
}

export default ErrorPageTemplate

import React from 'react'

import Figure from 'Figure'
import ArrowLink from 'ArrowLink'
import { HTMLContent } from 'Content'
import Reveal from 'Reveal'

const ErrorPage = ({ title, backgroundImages, body }) => (
  <div className='c-error-page c-hero'>
    <div className='c-hero__content o-container--xs'>
      <Reveal bottom ssrReveal>
        <HTMLContent
          content={body}
          className='e-h2 u-mar-b-large'
        />
        <ArrowLink to='/'>
          Home page
        </ArrowLink>
      </Reveal>
    </div>
    <Reveal ssrReveal>
      <div className='c-error-page__img o-fill-parent'>
        <Figure
          image={backgroundImages.desktop}
          className='o-fill-parent u-mar-b-none u-img-splash u-hide-completely-until@md'
          wrapperClassName='o-fill-parent'
          width={1312}
          sizes={{
            default: '100vw'
          }}
        />
        <Figure
          image={backgroundImages.mobile}
          className='o-fill-parent u-mar-b-none u-img-splash u-hide-completely@md'
          wrapperClassName='o-fill-parent'
          width={1312}
          sizes={{
            default: '100vw'
          }}
        />
      </div>
    </Reveal>
  </div>
)

export default ErrorPage
